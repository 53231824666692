import React, { useLayoutEffect, useState , useRef, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './img/logo.png';
import login from './img/login.png';
import ru from './img/ru.png';
import en from './img/en.png';

const Header = () => {
    const navigate = useNavigate();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('ru');
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedLanguage(event.target.value);
    };
    return (
        <header className="py-4 px-6 md:px-12 lg:px-20 mx-[3%] md:mx-[4%] lg:mx-[5%] bg-[#0A0F22] rounded-3xl">
        <div className="flex items-center justify-between w-full">
          <a href="/" >
          <img
            src={logo}
            alt="ISNLogo"
            className="w-16 h-16 object-contain"
          />
          </a>          
          <button
            className="lg:hidden text-white focus:outline-none"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>

          <nav className="hidden lg:flex space-x-8">
            <a href="/coin" className="text-white hover:text-[#CBFB5C] transition-colors duration-300 text-sm font-medium relative group">Монета</a>
            <a href="/phis_users" className="text-white hover:text-[#CBFB5C] transition-colors duration-300 text-sm font-medium relative group">Физ. лицам</a>
            <a href="/business" className="text-white hover:text-[#CBFB5C] transition-colors duration-300 text-sm font-medium relative group">Бизнесу</a>
            <a href="/bank" className="text-white hover:text-[#CBFB5C] transition-colors duration-300 text-sm font-medium relative group">Банк</a>
            <a href="/about" className="text-white hover:text-[#CBFB5C] transition-colors duration-300 text-sm font-medium relative group">О проекте</a>
            <a href="/statistic" className="text-white hover:text-[#CBFB5C] transition-colors duration-300 text-sm font-medium relative group">Статистика</a>
          </nav>

          <div className="hidden lg:flex items-center space-x-4">
            <select
              value={selectedLanguage}
              onChange={handleChange}
              className="bg-transparent text-white text-sm border-none outline-none cursor-pointer appearance-none pr-6"
            >
              <option value="ru">RU</option>
              <option value="en">EN</option>
            </select>
            <div className=" inset-y-0 right-0 flex items-center pointer-events-none">
              {selectedLanguage === 'ru' && (
                <img src={ru} alt="RU" className="w-5 h-5 ml-2" />
              )}
              {selectedLanguage === 'en' && (
                <img src={en} alt="EN" className="w-5 h-5 ml-2" />
              )}
            </div>
            <button
              className="bg-[#CBFB5C] text-black text-sm font-bold py-2 px-6 rounded-full hover:bg-yellow-400 transition-colors flex items-center"
              onClick={() => navigate('/login')}
            >
              <img
                src={login}
                alt="User Icon"
                className="w-5 h-5 mr-2"
              />
              <span>Личный кабинет</span>
            </button>
          </div>
        </div>

        {isMobileMenuOpen && (
          <div className="lg:hidden mt-4 space-y-2 bg-[#1A1A2E] rounded-2xl p-4 shadow-lg animate-fadeIn">
            <a href="/coin" className="block text-white hover:text-[#CBFB5C] transition-colors duration-300 py-2">Монета</a>
            <a href="/phis_users" className="block text-white hover:text-[#CBFB5C] transition-colors duration-300 py-2">Физ. лицам</a>
            <a href="/business" className="block text-white hover:text-[#CBFB5C] transition-colors duration-300 py-2">Бизнесу</a>
            <a href="/bank" className="block text-white hover:text-[#CBFB5C] transition-colors duration-300 py-2">Банк</a>
            <a href="/about" className="block text-white hover:text-[#CBFB5C] transition-colors duration-300 py-2">О проекте</a>
            <a href="/statistic" className="block text-white hover:text-[#CBFB5C] transition-colors duration-300 py-2">Статистика</a>
            <div className="flex items-center space-x-4">
              <select className="bg-transparent text-white text-sm border-none outline-none cursor-pointer">
                <option value="ru">RU</option>
                <option value="en">EN</option>
              </select>
              <button
                className="bg-[#CBFB5C] text-black text-sm font-bold py-2 px-6 rounded-full hover:bg-yellow-400 transition-colors flex items-center"
                onClick={() => navigate('/login')}
              >
                <img
                  src="/user-icon.png"
                  alt="User Icon"
                  className="w-5 h-5 mr-2"
                />
                <span>Личный кабинет</span>
              </button>
            </div>
          </div>
        )}
      </header>
    );
  };
  

export default Header;
