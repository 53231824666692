import React from "react";
import qr from "./img/qr.png";

const Footer = () => {
    return (
        <footer className="bg-dark-900 text-gray-300 mt-8 mx-[3%] md:mx-[5%] lg:mx-[5%]">
            <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 bg-[#08142A] rounded-[60px] p-8">
            <div>
                <h4 className="font-semibold mb-4 text-white text-xl">Подробнее об SIT</h4>
                <ul className="space-y-2">
                <li><a href="#" className="hover:text-white">О нас</a></li>
                <li><a href="#" className="hover:text-white">Связаться с нами</a></li>
                <li><a href="#" className="hover:text-white">Пользовательское соглашение</a></li>
                <li><a href="#" className="hover:text-white">Политика конфиденциальности</a></li>
                <li><a href="#" className="hover:text-white">О рисках</a></li>
                <li><a href="#" className="hover:text-white">Нарушения и уведомления</a></li>
                <li><a href="#" className="hover:text-white">О обработке запросов правоохранительных органов</a></li>
                <li><a href="#" className="hover:text-white">Приложение</a></li>
                </ul>
            </div>
            <div>
                <h4 className="font-semibold mb-4 text-white text-xl">Услуги</h4>
                <ul className="space-y-2">
                <li><a href="#" className="hover:text-white">Партнерство</a></li>
                <li><a href="#" className="hover:text-white">Комиссии</a></li>
                <li><a href="#" className="hover:text-white">Для бизнеса</a></li>
                </ul>
                <h4 className="font-semibold mb-4 text-white mt-4 text-xl">Поддержка</h4>
                <ul className="space-y-2">
                <li><a href="#" className="hover:text-white">Центр поддержки</a></li>
                <li><a href="#" className="hover:text-white">Защита от фишинга</a></li>
                <li><a href="#" className="hover:text-white">Объявления </a></li>
                <li><a href="#" className="hover:text-white">Сообщество SIT</a></li>
                </ul>
            </div>
            <div>
                <h4 className="font-semibold mb-4 text-white text-xl">Продукты</h4>
                <ul className="space-y-2">
                <li><a href="#" className="hover:text-white">Купить криптовалюту</a></li>
                <li><a href="#" className="hover:text-white">P2P-переводы</a></li>
                <li><a href="#" className="hover:text-white">Конвертировать</a></li>
                <li><a href="#" className="hover:text-white">Торговать</a></li>
                <li><a href="#" className="hover:text-white">SIT Wallet</a></li>
                <li><a href="#" className="hover:text-white">Рынок Web3</a></li>
                <li><a href="#" className="hover:text-white">Крипто-калькулятор</a></li>
                <li><a href="#" className="hover:text-white">Криптовалюты</a></li>
                </ul>
            </div>
            <div>
                <h4 className="font-semibold mb-4 text-white text-xl">Купить крипто</h4>
                <ul className="space-y-2">
                <li><a href="#" className="hover:text-white">Купить USDC</a></li>
                <li><a href="#" className="hover:text-white">Купить USDT</a></li>
                <li><a href="#" className="hover:text-white">Купить SFH</a></li>
                </ul>
                <h4 className="font-semibold mb-4 text-white text-xl mt-4">О проекте</h4>
                <ul className="space-y-2">
                <li><a href="#" className="hover:text-white">WhitePaper</a></li>
                <li><a href="#" className="hover:text-white">Дорожная карта</a></li>
                <li><a href="#" className="hover:text-white">Загрузки</a></li>
                </ul>
            </div>
            <div className="flex-col items-center bg-[#19263E] rounded-[60px] p-8 md:p-8 lg:p-8 xl:p-8">
                <h4 className="font-semibold mb-4 text-white text-xl">Присоединяйтесь к платформе</h4>
                <img src={qr} alt="QR Code" className="size-full md:size-auto mx-auto mb-4" />
                <p className="text-white text-center mb-4">Отсканируйте QR-код, чтобы скачать приложение SIT</p>
            </div>
            </div>
        </footer>
    );
  };
  

export default Footer;
