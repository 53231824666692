import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainPage from './MainPage';
import StatPage from './StatPage';
import ErrorPage from './ErrorPage';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/statistic" element={<StatPage />} />
      <Route path="/coin" element={<ErrorPage />} />
      <Route path="/phis_users" element={<ErrorPage />} />
      <Route path="/business" element={<ErrorPage />} />
      <Route path="/bank" element={<ErrorPage />} />
      <Route path="/about" element={<ErrorPage />} />
    </Routes>
  );
};

export default App;
