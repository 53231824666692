import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { ChartData } from 'chart.js';
import Footer from './Footer';
import Header from './Header';
import stat_img1 from './img/stat_img1.png';
import annotationPlugin from 'chartjs-plugin-annotation';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

interface StatCardProps {
  title: string;
  endpoint: string;
}

interface Data {
  count: number;
}

const StatCard: React.FC<StatCardProps> = ({ title, endpoint }) => {
    const [data, setData] = useState<Data | null>(null);
    const [error, setError] = useState<string | null>(null);
  
    useEffect(() => {
      fetch(endpoint)
        .then(response => response.json())
        .then(data => {
          setData(data);
        })
        .catch(error => {
          console.error('Ошибка при загрузке данных:', error);
          setError('Ошибка загрузки');
        });
    }, [endpoint]);
  
    return (
        <div className="bg-[#19263E] rounded-[20px] py-4 pr-4 shadow-md text-white flex ">
            <img src={stat_img1} alt="Icon" className="w-20 h-20" />
            <div>
              <div className="flex items-center justify-between mb-4">
                <h3 className="sm:text-base md:text-base lg:text-lg xl:text-xl font-semibold text-balance">{title}</h3>
              </div>
              <div className="flex items-center justify-between text-balance sm:text-base md:text-base lg:text-lg xl:text-xl font-bold mb-2 "  >
              {error ? (
                  error
              ) : data && typeof data.count !== 'undefined' ? (
                  data.count.toLocaleString()
              ) : (
                  'Will add soon!'
              )}
              </div>
            </div>
        </div>
    );
};
  
interface ApiResponse {
  chartLabels: string[];
  chartValues: number[];
  history: Array<{ id: number; recorded_at: string; rate: number }>;
  history2: Array<{ id: number; recorded_at: string; rate: number }>;
}

const StatPage: React.FC = () => {
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [selectedCurrency2, setSelectedCurrency2] = useState('USD');
  const [currencyRates, setCurrencyRates] = useState<Record<string, number>>({});
  const [chartData, setChartData] = useState<ChartData<'line'>>({
    labels: [],
    datasets: [],
  });
  const [selectedRange, setSelectedRange] = useState('1M');
  const [chartData2, setChartData2] = useState<ChartData<'line'>>({
    labels: [],
    datasets: [],
  });
  const [rateHistory, setRateHistory] = useState<ApiResponse['history']>([]);
  const [rateHistory2, setRateHistory2] = useState<ApiResponse['history2']>([]);
  const ranges = [
    { label: '1W', value: '1W' },
    { label: '1M', value: '1M' },
    { label: '3M', value: '3M' },
    { label: '6M', value: '6M' }
  ];

  const currencies = [
    { code: 'USD', name: 'Доллар' },
    { code: 'EUR', name: 'Евро' },
    { code: 'GBP', name: 'Фунт' },
    { code: 'TRY_RATE', name: 'Турецкая лира' },
    { code: 'KRW', name: 'Корейская вона' },
    { code: 'ZAR', name: 'Южноафриканский рэнд' },
    { code: 'SAR', name: 'Саудовский риал' },
    { code: 'RUB', name: 'Российский рубль' },
    { code: 'MXN', name: 'Мексиканское песо' },
    { code: 'JPY', name: 'Японская иена' },
    { code: 'AUD', name: 'Австралийский доллар' },
    { code: 'CAD', name: 'Канадский доллар' },
    { code: 'BRL', name: 'Бразильский реал' },
    { code: 'ARS', name: 'Аргентинское песо' },
    { code: 'GOLD', name: 'Золото' },
    { code: 'BYN', name: 'Белорусский рубль' },
    { code: 'CHF', name: 'Швейцарский франк' },
    { code: 'NZD', name: 'Новозеландский доллар' },
    { code: 'PLN', name: 'Польский злотый' },
    { code: 'SGD', name: 'Сингапурский доллар' },
    { code: 'ILS', name: 'Израильский шекель' },
    { code: 'THB', name: 'Таиландский бат' },
    { code: 'AED', name: 'Дирхам ОАЭ' },
    { code: 'JOD', name: 'Иорданский динар' },
    { code: 'KWD', name: 'Кувейтский динар' },
    { code: 'HKD', name: 'Гонконгский доллар' },
    { code: 'KYD', name: 'Кайманский доллар' },
  ];
  const invest_currencies = [
    { code: 'EUR', name: 'Евро' },
    { code: 'USD', name: 'Доллар' },
    { code: 'CNY', name: 'Китайская юань' },
    { code: 'RUB', name: 'Российский рубль' },
    { code: 'BYN', name: 'Белорусский рубль' },
    { code: 'AED', name: 'Дирхам ОАЭ' },
  ];

  useEffect(() => {
    const fetchCurrencyRates = async () => {
      try {
        const response = await fetch('https://sitworldstat.link/api/rates');
        const data = await response.json();
        console.log(data); 
        setCurrencyRates(data);
      } catch (err) {
        console.error('Ошибка при загрузке данных:', err);
      }
    };
  
    fetchCurrencyRates();
  }, []);

  const options = {
    plugins: {
      legend: {
        display: false, 
      },
    },
    elements: {
      point: {
        radius: 3,
      },
    },
  };
  
  

  useEffect(() => {
    const fetchCurrencyData = async () => {
      try {
        const response = await fetch(`https://sitworldstat.link/api/get_currency_data?currency=${selectedCurrency}`);
        const data: ApiResponse = await response.json();
        const response2 = await fetch(`https://sitworldstat.link/api/get_currency_data?currency=${selectedCurrency2}`);
        const data2: ApiResponse = await response2.json();
        const response21 = await fetch(`https://sitworldstat.link/api/get_currency_data?currency=${selectedCurrency2}`);
        const data21: ApiResponse = await response21.json();
        const lastValue = data21.chartValues[data21.chartValues.length - 1];
        setChartData({
          labels: data.chartLabels.reverse(),
          datasets: [
            {
              label: selectedCurrency,
              data: data.chartValues.reverse(),
              borderColor: 'rgba(75, 192, 192, 1)',
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              pointStyle: 'circle',
              tension: 0.4,
              
              pointRadius: (context) => context.dataIndex === data.chartValues.length - 1 ? 6 : 3,
              // pointBackgroundColor: (context) => context.dataIndex === data.chartValues.length - 1 ? 'rgba(255, 0, 0, 1)' : 'rgba(75, 192, 192, 1)',
            },
          ],
        });
        

        setChartData2({
          labels: data2.chartLabels.reverse(),
          datasets: [
            {
              label: `Стоимость инвестиционной монеты ${selectedCurrency2}`,
              data: data2.chartValues.map(value => value * 0.1).reverse(),
              borderColor: 'rgba(75, 192, 192, 1)',
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              pointStyle: 'circle',
              tension: 0.4,
            },
            {
              label: `Стоимость основной ЦФМ ${selectedCurrency2}`,
              data: Array(data2.chartLabels.length).fill(lastValue),
              borderColor: 'rgba(255, 0, 0, 1)',
              backgroundColor: 'rgba(255, 99, 132, 0.2)',
              pointStyle: 'none',
              borderDash: [5, 5],
              tension: 0,
            },
          ],
        });

        setRateHistory(data.history);
        setRateHistory2(
          data2.history.map(item => ({
            ...item,
            rate: item.rate * 0.10, 
          }))
        );
      } catch (err) {
        console.error('Ошибка при загрузке данных:', err);
      }
      
    };

    fetchCurrencyData();
  }, [selectedCurrency, selectedCurrency2, selectedRange]);

  const handleCurrencyChange = (currencyCode: string) => {
    setSelectedCurrency(currencyCode);
  };
  const handleCurrencyChange2 = (currencyCode: string) => {
    setSelectedCurrency2(currencyCode);
  };

  return (
    <div className="bg-[linear-gradient(114.54deg,_#001131_34.33%,_#161616_95.7%)] bg-[linear-gradient(132.6deg,_#001131_26.05%,_#161616_100%)]">
      <Header />
      <main className="py-8 px-4 md:px-8 lg:px-16">
        <h1 className="text-3xl font-bold mb-8 text-white text-center ">Статистика</h1>
        <h3 className="text-xl font-semibold mb-8 text-white text-center text-align:center underline decoration-inherit	">Данные по монете FEHU(SFH) согласно анализу внутренней системы</h3>
        <div className="grid sm:grid-cols-1  md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-6 mb-8">
          <StatCard title="Количество монет в системе SIT" endpoint="https://sitworldstat.link/api/get_last_coin_count" />
          <StatCard title="Количество монет в NEO банке" endpoint="https://sitworldstat.link/api/get_monthly_coin_addition" />
          <StatCard title="Оборот монет в системе за месяц" endpoint="https://sitworldstat.link/api/coins_moving" />
          <StatCard title="Количество операций за месяц" endpoint="https://sitworldstat.link/api/get_total_operations" />
        </div>
        <div className="grid sm:grid-cols-1  md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-6 mb-8">
          <StatCard title="Рыночная капитализация в евро" endpoint="https://sitworldstat.link/api/get_market_cap" />
          <StatCard title="Количество монет у пользователей" endpoint="https://sitworldstat.link/api/users_coin_count" />
          <StatCard title="Общее количество операций" endpoint="https://sitworldstat.link/api/get_total_operations" />
          <StatCard title="Количество зарегистрированных пользователей" endpoint="https://sitworldstat.link/api/users_count" />
        </div>
        <div className=" mx-0 py-4 my-8 px-0 w">
          <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-12 gap-8 ">
            <div className="col-span-3 bg-[#19263E] p-4 rounded-[20px] shadow-md w-[100%]">
              <h2 className="text-white mb-4">Валюты</h2>
              <ul className="overflow-auto max-h-96">
                {currencies.map((currency) => (
                  <li key={currency.code} className="mb-2">
                    <button
                      className={`w-full text-left p-2 rounded-md duration-300 ${
                        selectedCurrency === currency.code
                          ? 'bg-gradient-to-r from-yellow-400 to-transparent text-white'
                          : 'hover:bg-yellow-400 hover:text-white text-white'
                      }`}
                      onClick={() => handleCurrencyChange(currency.code)}
                    >
                      <div className="flex justify-between items-center">
                        <span>{currency.code === 'TRY_RATE' ? 'TRY' : currency.code}</span>
                        <span>
                          {currencyRates[currency.code.toLowerCase()] !== undefined
                            ? `${currencyRates[currency.code.toLowerCase()].toFixed(2)}`
                            : '-'}
                        </span>
                      </div>
                      <div className="text-left text-sm text-white">
                        {currency.name}
                      </div>
                    </button>
                    <hr />
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-span-6 bg-[#19263E] p-4 rounded-[20px] shadow-md mx-0 w-[100%]">
              <h2 className="text-white mb-4">Курс 1 {selectedCurrency + "/SFH"}</h2>
              <div className="mb-4">
                {ranges.map((range) => (
                  <button
                    key={range.value}
                    className={`mr-2 px-4 py-2 rounded ${selectedRange === range.value ? 'bg-blue-500 text-white' : 'bg-gray-800 text-gray-300'}`}
                    onClick={() => setSelectedRange(range.value)}
                  >
                    {range.label}
                  </button>
                ))}
              </div>
              <div>
                <Line data={chartData} options={options} />
              </div>
            </div>
            <div className="col-span-3 bg-[#19263E] p-4 rounded-[20px] shadow-md">
              <h2 className="text-white mb-4">История курса</h2>
              <div className="overflow-auto max-h-96">
                <table className="w-full text-left text-gray-300">
                  <thead>
                    <tr>
                      {/* <th className="p-2 border-b border-gray-700">ID</th> */}
                      <th className="p-2 border-b border-gray-700">Date</th>
                      <th className="p-2 border-b border-gray-700">Rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rateHistory.map((item) => (
                      <tr key={item.id} className="hover:bg-[#222]">
                        {/* <td className="p-2 border-b border-gray-700">{item.id}</td> */}
                        <td className="p-2 border-b border-gray-700">
                          {new Date(item.recorded_at).toLocaleString()}
                        </td>
                        <td className="p-2 border-b border-gray-700">{item.rate}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <h1 className="text-3xl font-bold mb-8 text-white text-center">Инвестиционная монета</h1>
        <h3 className="text-xl font-semibold mb-2 text-white text-center text-align:center underline decoration-inherit">Данные фонда развития инвестиционного проекта</h3>
        <h3 className="text-xl font-semibold mb-4 text-white text-center text-align:center underline decoration-inherit">Стать участником и держателем инвестиционной монеты фонда, вы можете после регистрации личного кабинета</h3>
        <div className="grid sm:grid-cols-1  md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-6 mb-8">
          <StatCard title="Монет в инвестиционном фонде" endpoint="https://sitworldstat.link/api/invest_system_count" />
          <StatCard title="Количество инвестиционных монет у пользователей" endpoint="https://sitworldstat.link/api/invest_users_count" />
          <StatCard title="Количество операций с инвестиционной монетой" endpoint="https://sitworldstat.link/api/invest_transaction_count" />
          <StatCard title="Рыночная капитализация инвестиционного фонда в евро" endpoint="https://sitworldstat.link/api/market_cap_eur" />
        </div>
        <div className="grid sm:grid-cols-1  md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-6 mb-8">
          <StatCard title="Прибыль за весь период в %" endpoint="https://sitworldstat.link/api/invest_income_all" />
          <StatCard title="Прибыль за последний месяц в %" endpoint="https://sitworldstat.link/api/invest_income_month" />
          <StatCard title="Прибыль за весь период в евро" endpoint="https://sitworldstat.link/api/invest_income_eur" />
          <StatCard title="Прибыль 1 iFehu в %" endpoint="https://sitworldstat.link/api/invest_ifehu" />
        </div>
        <div className=" mx-0 py-4 my-8 px-0 w">
          <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-12 gap-8 ">
            <div className="col-span-3 bg-[#19263E] p-4 rounded-[20px] shadow-md w-[100%]">
              <h2 className="text-white mb-4">Валюты</h2>
              <ul className="overflow-auto max-h-96">
                {invest_currencies.map((currency) => (
                  <li key={currency.code} className="mb-2">
                    <button
                      className={`w-full text-left p-2 rounded-md transition-all duration-300 ${
                        selectedCurrency2 === currency.code
                          ? 'bg-gradient-to-r from-yellow-400 to-transparent text-white'
                          : 'hover:bg-yellow-400 hover:text-white text-white'
                      }`}
                      onClick={() => handleCurrencyChange2(currency.code)}
                    >
                      <div className="flex justify-between items-center">
                        <span>{currency.code}</span>
                        <span>
                          {currencyRates[currency.code.toLowerCase()] !== undefined
                            ? `${(currencyRates[currency.code.toLowerCase()] * 0.1).toFixed(2)}`
                            : '-'}
                        </span>
                      </div>
                      <div className="text-left text-sm text-white">
                        {currency.name}
                      </div>
                    </button>
                    <hr />
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-span-6 bg-[#19263E] p-4 rounded-[20px] shadow-md mx-0 w-[100%]">
              <h2 className="text-white mb-4">Курс 1 {selectedCurrency2 + "/SFH"}</h2>
              <Line data={chartData2}/> 
            </div>
            <div className="col-span-3 bg-[#19263E] p-4 rounded-[20px] shadow-md">
              <h2 className="text-white mb-4">История курса</h2>
              <div className="overflow-auto max-h-96">
                <table className="w-full text-left text-gray-300">
                  <thead>
                    <tr>
                      {/* <th className="p-2 border-b border-gray-700">ID</th> */}
                      <th className="p-2 border-b border-gray-700">Date</th>
                      <th className="p-2 border-b border-gray-700">Rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rateHistory2.map((item) => (
                      <tr key={item.id} className="hover:bg-[#222]">
                        {/* <td className="p-2 border-b border-gray-700">{item.id}</td> */}
                        <td className="p-2 border-b border-gray-700">
                          {new Date(item.recorded_at).toLocaleString()}
                        </td>
                        <td className="p-2 border-b border-gray-700">{item.rate}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default StatPage;
